import styles from './Cover.module.css';
import StartArrow from '../StartArrow/StartArrow';

export default function Cover() {
  return (
    <div className={styles.Cover}>
      <div className={styles.Bottom}>
        <StartArrow />
      </div>
    </div>
  );
}