import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import './App.css';
import Header from './components/Header/Header';
import Cover from './components/Cover/Cover';
//import Animation from './components/Animation/Animation';

function App() {

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={
            <div>
              <Helmet>
                <title>Cameron Jiang</title>
                <meta name="description" content="Cameron Jiang" />
              </Helmet>
              <Cover />
            </div>
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;